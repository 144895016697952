import React, { Component } from "react";
import axios from "axios";
import forget from "./forget.svg";
import "./App.css";
import Form from "./components/form";
import Loader from "react-loader-spinner";

class App extends Component {
  state = {};
  handleClick = (email) => {
    console.log(email);
    if (this.validateEmail(email)) {
      this.postData(email);
      this.setState({ clicked: 1 });
    } else {
      this.setState({ validation: 1 });
    }
  };
  handleValidation = () => {};

  componentDidMount() {}

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  async postData(dodanyEmail) {
    const res = await axios.post("https://niezapominajka.jakubowski.waw.pl/api", {
      email: dodanyEmail,
    });
    const { data } = await res;
    console.log(data);
    data === "exists"
      ? this.setState({ alreadySigned: 1 })
      : this.setState({ alreadySigned: 0 });
  }
  render() {
    return (
      <div className="App ">
        <main role="main" className="inner cover App-header">
          <img src={forget} className="App-logo" alt="logo" />

          {this.state.alreadySigned ? (
            <span>Już jesteś na liście!</span>
          ) : this.state.alreadySigned === 0 ? (
            "Udało się! Dopisany!"
          ) : this.state.validation ? (
            <span>
              To nie jest poprawny adres e-mail! <br />
              Spróbuj ponownie...
            </span>
          ) : this.state.clicked ? (
            <Loader
              type="Watch"
              color="#fff"
              height={80}
              width={80}
              timeout={2000}
            />
          ) : (
            <Form onClick={this.handleClick} />
          )}
        </main>
      </div>
    );
  }
}

export default App;
