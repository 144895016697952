import React, { Component } from "react";

class Form extends Component {
  state = {};
  handleChange = (e) => {
    //console.log(e);
    this.setState({ input: e.target.value });
    //console.log(this.state.input);
  };
  render() {
    return (
      <React.Fragment>
        <p className="lead col-md-6">
          Kooperatywna Nie<span style={{ color: "red" }}>ZiP</span>ominajka nie
          pozwoli Ci przegapić żadnego ZiPu!
        </p>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control "
            placeholder="Twój email"
            aria-label="Username"
            aria-describedby="addon-wrapping"
            onChange={this.handleChange}
            required
          ></input>

          <button
            type="button"
            className="btn btn-success mt-3"
            onClick={() => this.props.onClick(this.state.input)}
          >
            Zapisz się!
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default Form;
